import { initForm, submitAndValidationForm } from "./generalForm.js"
import { handlePasswordInput } from "./passwordInput.js"
;("use strict")
;(() => {
    handlePasswordInput()

    document.querySelectorAll(".js-login-stop").forEach(
        (elem) =>
            (elem.onmousedown = function (event) {
                event.stopPropagation()
                event.preventDefault()
            })
    )

    const className = "validation-login-form"

    const form = initForm(className)

    const formElements = [
        {
            element: form.querySelector("#username")
        },
        {
            element: form.querySelector("#password")
        }
    ]

    submitAndValidationForm(form, formElements)
})()
