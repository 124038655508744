const showPasswordText = "Passwort anzeigen"
const hidePasswordText = "Passwort verstecken"

export const handlePasswordInput = () => {
    document.querySelectorAll(".show-password-button").forEach((button)=>{
          button.addEventListener(
            "click",
            (event) => {
                let inputElement = event.target.parentElement.parentElement.getElementsByTagName("input")[0]
                let buttonElement = event.target.parentElement
                let showPasswordImage = event.target.parentElement.getElementsByClassName("show-password-icon")[0]
                let hidePasswordImage = event.target.parentElement.getElementsByClassName("hide-password-icon")[0]

                if (inputElement.type === "text"){
                    buttonElement.title = showPasswordText
                    buttonElement.ariaLabel = showPasswordText
                    inputElement.setAttribute('type', 'password')
                    showPasswordImage.style["display"] = "unset"
                    hidePasswordImage.style["display"] = "none"
                } else {
                    buttonElement.title = hidePasswordText
                    buttonElement.ariaLabel = hidePasswordText
                    inputElement.setAttribute('type', 'text')
                    showPasswordImage.style["display"] = "none"
                    hidePasswordImage.style["display"] = "unset"
                }
            },
            false
          );
    });
}